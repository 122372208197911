import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useWindowContext } from "../globalApi/context/sizeContetx";
import HeaderLaptop from "./HeaderLaptop";
import HeaderMobile from "./HeaderMobile";
import i18next from "i18next";
import { formatOptions } from "../globalApi/utils/formats";
import { getAuthToken } from "../globalApi/utils/cookie";
import { AuthContext, AuthContextType } from "../globalApi/context/authContext";

const Header = () => {
  const { t } = useTranslation();
  const { atLeastMd } = useWindowContext();
  const options = formatOptions(i18next.languages);
  const authToken = getAuthToken();
  const { isAuth, signOut } = useContext(AuthContext) as AuthContextType;

  const links = [
    {
      name: t("headerLinks.rules"),
      href: "/rules",
    },
    {
      name: t("headerLinks.faq"),
      href: "/faq",
    },
    {
      name: t("headerLinks.contacts"),
      href: "/contacts",
    },
    // {
    //   name: 'Наши резервы',
    //   href: "/reserve",
    // },
    // {
    //   name: 'Гарантии надежности',
    //   href: "/warranties",
    // },
    // {
    //   name: 'Программа лояльности',
    //   href: "/loyality-program",
    // },
  ];

  if (atLeastMd) {
    return <HeaderLaptop options={options} links={links} isAuth={isAuth} signOut={signOut} />;
  } else {
    return <HeaderMobile options={options} links={links} isAuth={isAuth} signOut={signOut} />;
  }
};

export default Header;
