import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import { Navigate } from 'react-router-dom';

import Main from '../pages/Main';
import QrForm from '../pages/QrForm';
import Login from '../pages/Login';
import Header from './Header';
import Footer from './Footer';
import Cabinet from '../pages/Cabinet';
import PrivateLayout from './PrivateLayout';
import Rules from '../pages/Rules';
import FAQ from '../globalApi/pages/FAQ';
import ConfirmedOrder from '../pages/ConfirmedOrder';
import { useGetAppData } from '../globalApi/hooks/useGetAppData';
import { ConfigContext } from '../globalApi/context/configContext';
import { useTranslation } from 'react-i18next';
import Contacts from '../pages/Contacts';
import { useGetUniquePairs } from '../globalApi/apis/pairs';
import { SettingsContext, SettingsContextType } from '../globalApi/context/settingsContext';
import UpdatingWalletNodes from '../globalApi/pages/UpdatingWalletNodes';
import { Pair } from '../globalApi/types/pair';
import TechnicalProblem from './TechnicalProblems';

function App() {
  const configCtx = useContext(ConfigContext);
  const { i18n } = useTranslation();
  const [selectedPairId, setSelectedPairId] = useState<Pair['id']>('');
  const { clientId } = useGetAppData({ selectedPairId });
  const { isError } = useGetUniquePairs(clientId);
  const { status } = useContext(SettingsContext) as SettingsContextType;

  useEffect(() => {
    configCtx?.changeConfig('new-exchange');
  }, []);

  useEffect(() => {
    i18n.changeLanguage(configCtx?.config.defaultLang);
  }, [configCtx]);

  if (isError) return <TechnicalProblem />;
  if (!status) return <UpdatingWalletNodes />;

  return (
    <div className="font-sans overflow-hidden flex flex-col	justify-between	min-h-screen">
      <div>
        <Header />
        <main className="bg_page">
          <div className="container-fluid  mx-auto px-4 mb-20 md:mt-20 relative">
            <Routes>
              <Route index element={<Main
                clientId={clientId}
                selectedPairId={selectedPairId}
                setSelectedPairId={setSelectedPairId}
              />} />
              <Route path="qr/:id" element={<QrForm />} />
              <Route
                path="qr/:id/confirmed"
                element={<ConfirmedOrder />}
              />
              <Route path="/login" element={<Login />} />
              <Route
                path="/cabinet"
                element={
                  <PrivateLayout>
                    <Cabinet />
                  </PrivateLayout>
                }
              />
              <Route path="/rules" element={<Rules />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/contacts" element={<Contacts />} />
              {/* <Route path="/reserve" element={<Reserve />} /> */}
              {/* <Route path="/warranties" element={<Warranties />} /> */}
              {/* <Route
                path="/loyality-program"
                element={<LoyalityProgram />}
              /> */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default App;
