import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.scss";
import { BrowserRouter } from "react-router-dom";
import "./globalApi/i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { defaultErrorHandler } from "./globalApi/apis/utils";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import AuthContextProvider from "./globalApi/context/authContext";
import ConfigContextProvider from "./globalApi/context/configContext";
import SettingsContextProvider from "./globalApi/context/settingsContext";
import { WindowContextProvider } from "./globalApi/context/sizeContetx";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
      onError: defaultErrorHandler,
    },
    mutations: {
      retry: false,
      onError: defaultErrorHandler,
    },
  },
});


Sentry.init({
  dsn: "https://891af985b762432cbb80e491e53687fc@o1042004.ingest.sentry.io/4504011357159424",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT
});


root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <WindowContextProvider>
        <AuthContextProvider>
          <SettingsContextProvider>
            <ConfigContextProvider>
              <App />
            </ConfigContextProvider>
          </SettingsContextProvider>
        </AuthContextProvider>
      </WindowContextProvider>
    </QueryClientProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
