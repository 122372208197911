import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import Icon from "./Icon";
import { Option } from "../globalApi/types/global";
import Select from "react-select";
import { SettingsContext, SettingsContextType } from "../globalApi/context/settingsContext";
import { getDomainName } from "../globalApi/utils/utils";

interface Props {
  links: {
    name: string;
    href: string;
  }[];
  options: Option[];
  isAuth: boolean;
  signOut: VoidFunction;
}

const HeaderLaptop = ({ links, options, isAuth, signOut }: Props) => {
  const { i18n, t } = useTranslation();
  const { logo } = useContext(SettingsContext) as SettingsContextType;

  return (
    <header className="container mx-auto px-4 my-10">
      <div className="grid grid-cols-12 row-auto">
        <div className="grid grid-cols-8 row-auto col-span-8">
          <Link to="/" className="h-14 w-full col-span-2">
            <img className="h-full object-contain" src={logo} alt="logo" />
          </Link>
          <nav className="flex items-center col-span-6 justify-evenly">
            {links.map(({ name, href }) => (
              <Link
                key={name}
                className="ml-3 text-center text-secondaryText transition"
                to={href}
              >
                {name}
              </Link>
            ))}
          </nav>
        </div>
        <div className="flex items-center col-span-4 justify-end">
          {/* <Select
          className="text-black"
          options={options}
          value={options.find(
            (option) => option.value === i18n.resolvedLanguage
          )}
          onChange={(option) => i18n.changeLanguage(option?.value)}
        /> */}
          <Link
            to="/login"
            className="flex ml-2 items-center px-3 rounded-lg bg-accent transition h-full text-sm"
          >
            <Icon name="arrow_forward" className="mr-2" />
            {t(isAuth ? "personalCabinet" : "signIn")}
          </Link>
          {isAuth && (
            <button
              className="flex ml-2 items-center px-3 rounded-lg bg-accent transition h-full text-sm orange_link"
              type="button"
              onClick={signOut}
            >
              {t("signOut")}
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default HeaderLaptop;
