import React, { useState, useEffect, useMemo, memo, useRef } from 'react';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { validate } from 'crypto-address-validator-ts';
import Select from 'react-select';

import ErrorMessage from './ui/ErrorMessage';
import { useUpdateTransaction } from '../globalApi/apis/transactions';
import { Transaction, TransactionForm } from '../globalApi/types/transaction';
import { getCookies } from '../globalApi/utils/cookie';
import { useTranslation } from 'react-i18next';
import { isLocal } from '../globalApi/utils/utils';
import { getCurrencyName } from '../globalApi/utils/formats';
import { useGetExtraFormData } from '../globalApi/hooks/useGetExtraFormData';
import { useGetRate } from '../globalApi/hooks/useGetRate';
import { PairsProps, TransferFormProps } from '../globalApi/props/interfaces';
import { useGetAvailablePairs } from '../globalApi/hooks/useGetAvailablePairs';
import { Currency, CurrencyFrom } from '../globalApi/types/pair';

type Props = TransferFormProps & PairsProps;

const TransferForm = ({
  selectedPair,
  toItem,
  fromItem,
  pairs,
  handleSelectFromItem,
  handleSelectToItem,
}: Props) => {
  const formikRef: React.Ref<FormikProps<TransactionForm>> | null =
    useRef(null);
  const { t } = useTranslation();

  const generalValidation = useMemo(
    () => ({
      email: Yup.string()
        .email(t('base.emailIsInvalid'))
        .required(t('base.required')),
      addressSending: Yup.string().required(t('base.required')),
      amountFrom: Yup.number()
        .typeError(t('base.mustBeANumber'))
        .required(t('base.required')),
      amountTo: Yup.number()
        .typeError(t('base.mustBeANumber'))
        .required(t('base.required')),
    }),
    [t]
  );

  const navigate = useNavigate();
  const [validation, setValidation] = useState(generalValidation);
  const updateTransactionMutation = useUpdateTransaction();

  const { percent, min, max } = useGetExtraFormData({
    selectedPair,
  });
  const { rate, fromItemPrice: amount, renderRate } = useGetRate({
    selectedPair,
    percent,
  });

  const { availableCurrenciesFrom, availableCurrenciesTo } = useGetAvailablePairs({
    pairs, fromItem,
  });

  const emailFromCookies = useMemo(() => getCookies('email') || '', []);

  useEffect(() => {
    if (formikRef.current) {
      const { setFieldValue, values } = formikRef.current;
      const newAmount = values.amountFrom * Number(rate);
      setFieldValue('amountTo', newAmount);
    }
  }, [rate]);

  useEffect(() => {
    setValidation((prevState) => ({
      ...prevState,
      amountFrom: Yup.number()
        .max(max, t('base.mustBeLessThanOrEqual', { value: max }))
        .min(min, t('base.mustBeGreaterThanOrEqual', { value: min }))
        .typeError('Must be a number')
        .required(t('base.required')),
      addressSending: Yup.string()
        .test('crypto-validate', 'Wallet is not valid', (value) => {
          if (isLocal()) return true;
          if (toItem?.network === 'TRC20' && value) {
            return /T[A-Za-z1-9]{33}/.test(value);
          }

          return validate(value as string, toItem?.symbol || '', {
            networkType: toItem?.network || toItem?.symbol || '',
            chainType: '',
          });
        })
        .required(t('base.required')),
    }));
    formikRef.current?.validateForm();
  }, [max, min, fromItem, toItem]);

  const initialValues: TransactionForm = {
    email: emailFromCookies,
    addressSending: '',
    amountFrom: min || 1,
    amountTo: 0,
  };

  const handleSubmit = async (values: TransactionForm) => {
    const data: Transaction = {
      ...values,
      currencyFromId: fromItem?.id || '',
      currencyToId: toItem?.id || '',
      status: 'created',
      amount: amount * Number(values.amountFrom),
      percent,
    };
    updateTransactionMutation.mutateAsync(
      { data },
      {
        onSuccess: (data) => navigate(`/qr/${data.data.model.id}`),
      }
    );
  };

  return (
    <div className="exchange">
      <div className="container jc-c">
        <div className="colm-8">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape(validation)}
            enableReinitialize
            innerRef={formikRef}
          >
            {({ setFieldValue }) => {
              return (
                <Form className="blur-bg w-full rounded-lg mt-3">
                  <h4>Bведите данные для обмена</h4>
                  <p>Курс: {renderRate}</p>
                  <div>
                    <div className="select-group">
                      <div>
                        <Select
                          className="SelectField"
                          value={fromItem as Currency}
                          onChange={(option) => handleSelectFromItem(option as CurrencyFrom)}
                          options={availableCurrenciesFrom}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => getCurrencyName(option)}
                        />
                      </div>
                      <div>
                        <Select
                          className="SelectField"
                          value={toItem}
                          onChange={(option) => handleSelectToItem(option as Currency)}
                          options={availableCurrenciesTo}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => getCurrencyName(option)}
                          isOptionDisabled={(option) => !option.isAvailable}
                        />
                      </div>
                    </div>
                  </div>
                  <h4 className="mt-1">Данные получателя</h4>
                  <div className="flex justify-between">
                    <span>{getCurrencyName(fromItem)}</span>
                    <span>{getCurrencyName(toItem)}</span>
                  </div>
                  <div className="input-group mb-5">
                    <label>
                      <Field
                        name="amountFrom"
                        placeholder={min}
                        className="mt-3 rounded-lg bg-main w-full p-3 text-black"
                        type="number"
                        onChange={(e: React.ChangeEvent<{ value: string }>) => {
                          setFieldValue('amountFrom', e.target.value);
                          setFieldValue(
                            'amountTo',
                            Number(e.target.value) * Number(rate)
                          );
                        }}
                        disabled={!fromItem || !toItem}
                      />
                      <ErrorMessage name="amountFrom" />
                    </label>
                    <label>
                      <Field
                        name="amountTo"
                        placeholder="400"
                        className="mt-3 rounded-lg bg-main w-full p-3 text-black"
                        type="number"
                        disabled
                      />
                      <ErrorMessage name="amountTo" />
                    </label>
                  </div>
                  <div className="input-group">
                    <label>
                      Email:
                      <Field
                        disabled={!!emailFromCookies}
                        name="email"
                        placeholder="E-mail"
                        className="mt-3 rounded-lg bg-main w-full p-3 text-black"
                      />
                      <ErrorMessage name="email" />
                    </label>
                    <label>
                      Кошелек:
                      <Field
                        name="addressSending"
                        placeholder={`${getCurrencyName(toItem)} ${t(
                          'pairs.wallet'
                        )}`}
                        className="mt-3 rounded-lg bg-main w-full p-3 text-black"
                      />
                      <ErrorMessage name="addressSending" />
                    </label>
                  </div>

                  <p>
                    Нажимая кнопку “Перейти к оплате, я соглашаюсь с{' '}
                    <Link to="/rules">Правилами обмена</Link>
                  </p>
                  <button
                    type="submit"
                    className="rounded-lg mt-4 bg-accent text-white w-full py-3 transition"
                  >
                    {t('pairs.proceedToCheckout')}
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default memo(TransferForm);
