import React, { useEffect, useMemo, useContext } from 'react';
import { useGetUniquePairs } from '../globalApi/apis/pairs';
import { getResponseList } from '../globalApi/apis/utils';
import Loader from '../components/Loader';
import TechnicalProblem from '../components/TechnicalProblems';
import first from '../assets/1.png';
import second from '../assets/2.png';
import third from '../assets/3.png';
import fourth from '../assets/4.png';
import TinySlider, { TinySliderSettings } from 'tiny-slider-react';
import TransferForm from '../components/TransferForm';
import { ConfigContext } from '../globalApi/context/configContext';
import { MainProps } from '../globalApi/props/interfaces';
import { useGetSelectedCurrencies } from '../globalApi/hooks/useGetSelectedCurrencies';
import useGetSelectedPair from '../globalApi/hooks/useGetSelectedPair';

const sliderSettings: TinySliderSettings = {
  items: 2,
  slideBy: 'page',
  autoplay: false,
  controls: true,
  autoplayButton: false,
  autoplayButtonOutput: false,
  nav: true,
  speed: 2000,
  responsive: {
    200: {
      items: 1,
    },
    768: {
      items: 2,
    },
  },
};

const Main = ({ clientId, selectedPairId, setSelectedPairId }: MainProps) => {
  const configCtx = useContext(ConfigContext);
  const { data, refetch, isLoading, isError } = useGetUniquePairs(clientId);
  const pairs = useMemo(() => getResponseList(data), [data]);

  useEffect(() => {
    if (!clientId || !configCtx) return;
    const timeout = 1000 * 60 * configCtx.config.refetchTime;
    const interval = setInterval(refetch, timeout);
    return () => clearInterval(interval);
  }, [clientId, configCtx, refetch]);

  const selectedPair = useGetSelectedPair({ selectedPairId, pairs });

  const { handleSelectFromItem, handleSelectToItem, toItem, fromItem } =
    useGetSelectedCurrencies({
      pairs,
      selectedPairId,
      setSelectedPairId,
    });

  if (isError) return <TechnicalProblem />;

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="bg">
        <section className="hero">
          <div className="container">
            <h1>Обменивай безопасно</h1>
            <p>
              Наша главная задача - безопасные и быстрые обмены для наших
              клиентов. Попробуйте наш сервис для того, чтобы ощутить все
              преимущества!
            </p>
            <div>
              <span className="material-symbols-outlined material-icons">
                done <span>Работаем круглосуточно</span>
              </span>
            </div>
          </div>
        </section>

        <section>
          <div className="how_it_works">
            <div className="container">
              <h2>Как это работает</h2>
              <div className="lines">
                <div>
                  <div>
                    <img src={first} alt="currency" />
                    <p>выберите нужную валюту</p>
                  </div>

                  <div>
                    <span className="material-symbols-outlined material-icons">
                      east
                    </span>
                  </div>

                  <div>
                    <img src={second} alt="email" />
                    <p>введите вашу почту</p>
                  </div>

                  <div>
                    <span className="material-symbols-outlined material-icons">
                      east
                    </span>
                  </div>

                  <div>
                    <img src={third} alt="wallet" />
                    <p>введите номер вашего кошелька</p>
                  </div>

                  <div>
                    <span className="material-symbols-outlined material-icons">
                      east
                    </span>
                  </div>

                  <div>
                    <img src={fourth} alt="get your money" />
                    <p>получите ваши средства</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="testimonials">
            <div className="container">
              <h2>Отзывы</h2>
              <div className="testimonials">
                <div className="my-slider">
                  <TinySlider settings={sliderSettings}>
                    <div>
                      <div className="card">
                        <span className="circle__slider"></span>
                        <span className="name">Лидия, 28.10.2022, 00:19</span>
                        <p>
                          Доброе время суток! Очень нравится этот обменник, всё
                          происходит в течении часа. Быстро. Благодарю.
                          Рекомендую.
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <span className="circle__slider"></span>
                        <span className="name">Илья, 24.10.2022, 18:15</span>
                        <p>
                          Впервые воспользовался данным сервисом, перевод был
                          зачислен в течении пары часов как и было заявлено, но
                          самое главное что все пришло до копеечки. Спасибо буду
                          пользоваться еще!
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <span className="circle__slider"></span>
                        <span className="name">Василий, 23.10.2022, 13:12</span>
                        <p>
                          Меньше чем в 30 секунд перевод совершился ! Спасибо !
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <span className="circle__slider"></span>
                        <span className="name">
                          Владимир, 20.10.2022, 20:25
                        </span>
                        <p>
                          Спасибо за быстрый и качественный обмен. Я пользовался
                          вашим сервисом впервые и немного волновался, но все
                          прошло без сучка и задоринки. Молодцы!
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <span className="circle__slider"></span>
                        <span className="name">Петр, 18.10.2022, 12:10</span>
                        <p>
                          Делал перевод по направлению BTC - Тинькоф. Как только
                          появилось первое подтверждение моментально на карту
                          прилетели средства. Отличный обменник!!!!
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <span className="circle__slider"></span>
                        <span className="name">Наталья, 16.10.2022, 03:34</span>
                        <p>
                          Обмен прошёл быстро и надежно. Поменяла по хорошему
                          курсу без регистраций)
                        </p>
                      </div>
                    </div>
                  </TinySlider>
                </div>
              </div>
            </div>
          </div>
          <TransferForm
            {...{
              pairs,
              handleSelectFromItem,
              handleSelectToItem,
              toItem,
              fromItem,
              selectedPair,
            }}
          />
        </section>
      </div>
    </>
  );
};

export default Main;
