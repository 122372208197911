import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../components/Icon';
import {
  SettingsContext,
  SettingsContextType,
} from '../globalApi/context/settingsContext';
import { getDomainName } from '../globalApi/utils/utils';

const Contacts = () => {
  const { support, name } = useContext(SettingsContext) as SettingsContextType;
  const { t } = useTranslation();
  const gmailLink = `support@${getDomainName()}`;
  const TGName = `${name} support`;

  return (
    <div className="h-full w-full flex justify-center items-center">
      <div className="blur-bg rounded-lg">
        <div className="p-8">
          <h2 className="text-center font-bold md:text-3xl text-xl mb-5">
            {t('headerLinks.contacts')}
          </h2>
          <p className="mb-5">{t('contacts.description')}</p>
          <div className="flex justify-evenly flex-col md:flex-row">
            <div>
              <p className="text-lg mb-3 font-bold">{t('contacts.support')}</p>
              <div className="flex items-center mb-5">
                <Icon
                  name="query_builder"
                  className="btn-orange p-2 rounded-lg text-white	"
                />
                <div className="ml-4">
                  <p className="text-sm">{t('contacts.workTime')}:</p>
                  <p className="font-bold">{t('contacts.aroundTheClock')}</p>
                </div>
              </div>
              <div className="flex items-center mb-5">
                <Icon
                  name="mail"
                  className="btn-orange p-2 rounded-lg text-white	"
                />
                <div className="ml-4">
                  <p className="text-sm">{t('contacts.supportByEmail')}:</p>
                  <a href={`mailto:${gmailLink}`} className="font-bold">
                    {gmailLink}
                  </a>
                </div>
              </div>
              <div className="flex items-center mb-5">
                <Icon
                  name="telegram"
                  className="btn-orange p-2 rounded-lg text-white	"
                />
                <div className="ml-4">
                  <p className="text-sm">{t('contacts.supportByTg')}:</p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={support}
                    className="font-bold"
                  >
                    {TGName}
                  </a>
                </div>
              </div>
            </div>
            <div>
              <p className="text-lg mb-3 font-bold">
                {t('contacts.collaboration')}
              </p>
              <div className="flex items-center mb-5">
                <Icon
                  name="query_builder"
                  className="btn-orange p-2 rounded-lg text-white	"
                />
                <div className="ml-4">
                  <p className="text-sm">{t('contacts.workTime')}:</p>
                  <p className="font-bold">{t('contacts.aroundTheClock')}</p>
                </div>
              </div>
              <div className="flex items-center mb-5">
                <Icon
                  name="mail"
                  className="btn-orange p-2 rounded-lg text-white	"
                />
                <div className="ml-4">
                  <p className="text-sm">{t('contacts.supportByEmail')}:</p>
                  <a href={`mailto:${gmailLink}`} className="font-bold">
                    {gmailLink}
                  </a>
                </div>
              </div>
              <div className="flex items-center mb-5">
                <Icon
                  name="telegram"
                  className="btn-orange p-2 rounded-lg text-white	"
                />
                <div className="ml-4">
                  <p className="text-sm">{t('contacts.supportByTg')}:</p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={support}
                    className="font-bold"
                  >
                    {TGName}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <a
            href={support}
            className="mt-10 py-3 text-lg font-bold rounded-lg btn-orange transition w-full block text-center text-white"
          >
            {t('contacts.writeToOperator')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
